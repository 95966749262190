// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Search_search_ticky_sidebar_1vhvC{position:sticky;top:103px;height:100%}", ""]);
// Exports
exports.locals = {
	"search_ticky_sidebar": "Search_search_ticky_sidebar_1vhvC"
};
module.exports = exports;
