<template>
  <ais-index index-name="dev_products">
    <div class="modal_search-ttl">
      <h4>Sản phẩm</h4>
    </div>
    <ais-hits>
      <template v-slot="{ items, sendEvent }">
        <ul class="search-products-list" id="dosiin_product-keys-list">
          <li
            class="search_item product_item-inner"
            v-for="product in items"
            :key="product.objectID"
          >
            <div class="product-item product-item-sm">
              <router-link
                :to="_productUrl(product)"
                @click.native="sendEvent('click', product, 'product - search - clicked')"
                :title="product.product"
                class="width-product-img"
              >
                <img
                  class="product-item_img"
                  v-lazy="
                    _productThumbnailSticker(product.main_pair, product.sticker.main_pair)
                  "
                  :title="product.product"
                  width="108"
                  height="108"
                />
              </router-link>
              <dl class="product-item_summary">
                <h5 class="product-item_title_text">
                  <router-link
                    :to="_brandUrl(product.seo_name_company)"
                    :title="product.company_name"
                    v-text="product.company_name"
                  ></router-link>
                </h5>
                <dt class="product-item_name truncate">
                  <router-link :to="_productUrl(product)" :title="product.product">
                    <ais-highlight
                      :class-names="{
                        'ais-Highlight-highlighted': 'primary-link_text',
                      }"
                      attribute="product"
                      :hit="product"
                    />
                  </router-link>
                </dt>
                <div class="product-item-price_wrapper">
                  <div
                    class="product-item_sale"
                    v-if="product.list_price > product.price"
                  >
                    <dd class="inline-bl _sale_origin product-item_sale_origin">
                      {{ currencyFormat(product.list_price) }}
                    </dd>
                    <dd
                      class="inline-bl _sale_origin product-item_sale_discount"
                      v-if="discountPrc(product) && discountPrc(product) > 0"
                    >
                      -{{ Math.floor(discountPrc(product)) }}%
                    </dd>
                  </div>
                  <dd class="product-item_price">{{ currencyFormat(product.price) }}</dd>
                </div>
              </dl>
            </div>
          </li>
        </ul>
      </template>
    </ais-hits>
    <AlgoligaPagination />
  </ais-index>
</template>

<script>
import AlgoligaPagination from "@/algolia/search/desktop/Pagination";
export default {
  components: {
    AlgoligaPagination,
  },
};
</script>
