<template>
    <section class="search-page-section">
        <ais-index index-name="dev_products_query_suggestions">
            <div class="header-section">
                <h3  class="header-section-left-side section-title">Từ khoá</h3>
            </div>
        
            <ais-hits :class-names="{'ais-Hits' : 'tag-list'}">
                <template v-slot="{ items }">
                    <ul class="tag-list-wrapper">
                        <li class="tag-name kw-hot-search" v-for="(item , index) in items" :key="index" >
                            <router-link class="text" :to="`/search?keyword=${item.query}`" :title="item.query" replace>#{{ item.query }}</router-link>
                        </li>   
                    </ul>
                </template>
            </ais-hits>
        </ais-index>
    </section>
</template>
