<template>
  <ais-index index-name="dev_companies">
    <div class="modal_search-ttl">
      <h4>Thương hiệu</h4>
    </div>
    <ais-hits>
      <template v-slot="{ items }">
        <ul class="search-brands-list" id="dosiin_brand-keys-list">
          <li class="search_item" v-for="item in items" :key="item.objectID">
            <div class="brand-item-sm brand-item-sm-box">
              <router-link
                class="search_brand-img"
                :to="_brandUrl(item.seo_name)"
                :title="item.page_title"
              >
                <img
                  width="62"
                  height="62"
                  style="width: 62px; height: 62px"
                  v-lazy="_companyAvatar(item.logos.theme)"
                  class="brand-logo_img"
                  :alt="item.company"
                />
              </router-link>
              <dl class="brand-sm_desc">
                <router-link :to="_brandUrl(item.seo_name)" :title="item.page_title">
                  <dt class="brand_name">
                    <ais-highlight
                      :class-names="{
                        'ais-Highlight-highlighted': 'primary-link_text',
                      }"
                      attribute="company"
                      :hit="item"
                    />
                  </dt>
                  <dd class="brand_social">{{ item.follow_count }} người theo dõi</dd>
                  <dd class="brand_social">{{ item.products_count }} sản phẩm</dd>
                </router-link>
              </dl>
              <router-link
                class="visit_store-brand d-flex align-items-center"
                :to="_brandUrl(item.seo_name)"
                :title="item.page_title"
              >
                <span class="dosiin_mr-2">Ghé cửa hàng</span
                ><i class="dsi dsi-manage"></i>
              </router-link>
            </div>
          </li>
        </ul>
      </template>
    </ais-hits>
    <AlgoligaPagination />
  </ais-index>
</template>

<script>
import AlgoligaPagination from "@/algolia/search/desktop/Pagination";
export default {
  components: {
    AlgoligaPagination,
  },
};
</script>
