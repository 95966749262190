<template>
  <ais-instant-search
    index-name="dev_products"
    :search-client="searchClientKeys"
    :middlewares="middlewares"
  >
    <ais-configure
      :enable-personalization.camel="true"
      :hitsPerPage="8"
      :query="keyword"
    />

    <div
      class="search_page-inner dosiin_padding-header-top dosiin-page-min-height dosiin_page-max-width"
    >
      <div class="search_page-left" :class="[$style.search_ticky_sidebar]">
        <section class="result-filter">
          <div class="result-search">
            <h3>Tìm kiếm</h3>
            <p v-text="keyword.length ? `Từ khoá '${keyword}'` : 'Chưa nhập từ khoá'"></p>
          </div>
          <ul class="result-filter_keys dosiin_filter-tabs">
            <li
              class="result-filter_key filter-tab-unset dosiin_filter-tab"
              :class="query.tab == 'all' ? 'active' : ''"
              @click="viewResult('all')"
            >
              <span class="text">Nổi bật</span>
            </li>

            <ais-index index-name="dev_products">
              <ais-stats>
                <template v-slot="{ nbHits }">
                  <li
                    class="result-filter_key filter-tab-unset dosiin_filter-tab"
                    :class="query.tab == 'product' ? 'active' : ''"
                    @click="viewResult('product')"
                  >
                    <span class="text">Sản phẩm </span>
                    <span class="text-pink-gradient no-weight" v-if="keyword.length">
                      ({{ showLimitStat(nbHits) }})</span
                    >
                  </li>
                </template>
              </ais-stats>
            </ais-index>

            <ais-index index-name="dev_companies">
              <ais-stats>
                <template v-slot="{ nbHits }">
                  <li
                    class="result-filter_key filter-tab-unset dosiin_filter-tab"
                    :class="query.tab == 'brand' ? 'active' : ''"
                    @click="viewResult('brand')"
                  >
                    <span class="text">Thương hiệu </span>
                    <span class="text-pink-gradient no-weight" v-if="keyword.length">
                      ({{ showLimitStat(nbHits) }})</span
                    >
                  </li>
                </template>
              </ais-stats>
            </ais-index>

            <!-- <li class="result-filter_key filter-tab-unset">
                            <span class="text"> Mọi người </span><span class="text-pink-gradient no-weight"> (99+)</span>
                        </li> -->
            <ais-index index-name="dev_vnfds">
              <ais-stats>
                <template v-slot="{ nbHits }">
                  <li
                    class="result-filter_key filter-tab-unset dosiin_filter-tab"
                    :class="query.tab == 'vnfd' ? 'active' : ''"
                    @click="viewResult('vnfd')"
                  >
                    <span class="text">Bài viết </span>
                    <span class="text-pink-gradient no-weight" v-if="keyword.length">
                      ({{ showLimitStat(nbHits) }})</span
                    >
                  </li>
                </template>
              </ais-stats>
            </ais-index>
          </ul>
        </section>
        <!-- <section class="search_filter dosiin_mt-16">
                    <div class="search_filter-inner">
                        <h4 class="search_filter-ttl dosiin_mb-16"> Bộ lọc tìm kiếm</h4>
                        <ul>
                            <li>
                                <p>Mọi người</p>
                                <div class="input-group-text">
                                    <input id="search-filter" type="checkbox" aria-label="Checkbox for following text input">
                                    <label for="search-filter"></label>
                                </div>
                            </li>
                            <li>
                                <p>Người bạn đang theo dõi</p>
                                <div class="input-group-text">
                                    <input id="filter-flow" type="checkbox" aria-label="Checkbox for following text input">
                                    <label for="filter-flow"></label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section> -->
      </div>

      <div class="search_page-middle">
        <section class="search-list-container" v-if="query.tab == 'all' || !query.tab">
          <div class="result-hashtag" id="result-pain">
            <div class="modal_search-ttl">
              <h4>Từ khoá</h4>
            </div>

            <div class="modal_search-hastag">
              <ais-index index-name="dev_products_query_suggestions">
                <ais-hits ref="searchHits">
                  <template v-slot="{ items }">
                    <div class="search_key">
                      <ul>
                        <li v-for="(item, index) in items" :key="index">
                          <i class="dsi dsi-s16 dsi-search"></i>
                          <router-link
                            :to="{ name: 'search', query: { keyword: item.query } }"
                            :title="item.query"
                            replace
                          >
                            <span>{{ item.query }}</span>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </template>
                </ais-hits>
              </ais-index>
            </div>

            <div class="bars_inner">Shopping</div>
            <ais-index index-name="dev_products">
              <ais-stats>
                <template v-slot="{ nbHits }">
                  <div class="modal_search-ttl">
                    <h4>Sản phẩm</h4>
                    <div
                      class="modal_search-ttl-chil"
                      v-if="keyword.length"
                      @click="viewResult('product')"
                    >
                      <p>Xem thêm {{ showLimitStat(nbHits) }} kết quả</p>
                      <i class="dsi dsi-act-arrow-forward"></i>
                    </div>
                  </div>
                </template>
              </ais-stats>

              <ais-hits>
                <template v-slot="{ items, sendEvent }">
                  <ul class="search-products-list" id="dosiin_product-keys-list">
                    <li
                      class="search_item product_item-inner"
                      v-for="product in items"
                      :key="product.objectID"
                    >
                      <div class="product-item product-item-sm">
                        <router-link
                          :to="_productUrl(product)"
                          @click.native="
                            sendEvent('click', product, 'product - search - clicked')
                          "
                          :title="product.product"
                          class="width-product-img"
                        >
                          <img
                            class="product-item_img"
                            v-lazy="
                              _productThumbnailSticker(
                                product.main_pair,
                                product.sticker.main_pair
                              )
                            "
                            :title="product.product"
                            width="108"
                            height="108"
                          />
                        </router-link>
                        <dl class="product-item_summary">
                          <h5 class="product-item_title_text">
                            <router-link
                              :to="_brandUrl(product.seo_name_company)"
                              :title="product.company_name"
                              v-text="product.company_name"
                            ></router-link>
                          </h5>
                          <dt class="product-item_name truncate">
                            <router-link
                              :to="_productUrl(product)"
                              :title="product.product"
                            >
                              <ais-highlight
                                :class-names="{
                                  'ais-Highlight-highlighted': 'primary-link_text',
                                }"
                                attribute="product"
                                :hit="product"
                              />
                            </router-link>
                          </dt>
                          <div class="product-item-price_wrapper">
                            <div
                              class="product-item_sale"
                              v-if="product.list_price > product.price"
                            >
                              <dd class="inline-bl _sale_origin product-item_sale_origin">
                                {{ currencyFormat(product.list_price) }}
                              </dd>
                              <dd
                                class="inline-bl _sale_origin product-item_sale_discount"
                                v-if="discountPrc(product) && discountPrc(product) > 0"
                              >
                                -{{ Math.floor(discountPrc(product)) }}%
                              </dd>
                            </div>
                            <dd class="product-item_price">
                              {{ currencyFormat(product.price) }}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </li>
                  </ul>
                </template>
              </ais-hits>
            </ais-index>

            <ais-index index-name="dev_companies">
              <ais-stats>
                <template v-slot="{ nbHits }">
                  <div class="modal_search-ttl">
                    <h4>Thương hiệu</h4>
                    <div
                      class="modal_search-ttl-chil"
                      v-if="keyword.length"
                      @click="viewResult('brand')"
                    >
                      <p>Xem thêm {{ showLimitStat(nbHits) }} kết quả</p>
                      <i class="dsi dsi-act-arrow-forward"></i>
                    </div>
                  </div>
                </template>
              </ais-stats>
              <ais-hits>
                <template v-slot="{ items }">
                  <ul class="search-brands-list" id="dosiin_brand-keys-list">
                    <li class="search_item" v-for="item in items" :key="item.objectID">
                      <div class="brand-item-sm brand-item-sm-box">
                        <BrandLogo
                          :width="40"
                          :height="40"
                          :brand="item"
                          imageClass="brand-logo_img"
                        />

                        <dl class="brand-sm_desc">
                          <router-link
                            :to="_brandUrl(item.seo_name)"
                            :title="item.page_title"
                          >
                            <dt class="brand_name">{{ item.company }}</dt>
                            <dd class="brand_social">
                              {{ item.follow_count }} người theo dõi
                            </dd>
                            <dd class="brand_social">
                              {{ item.products_count }} sản phẩm
                            </dd>
                          </router-link>
                        </dl>
                        <router-link
                          class="visit_store-brand d-flex align-items-center"
                          :to="_brandUrl(item.seo_name)"
                          :title="item.page_title"
                        >
                          <span class="dosiin_mr-2">Ghé cửa hàng</span
                          ><i class="dsi dsi-manage"></i>
                        </router-link>
                      </div>
                    </li>
                  </ul>
                </template>
              </ais-hits>
            </ais-index>
            <div class="bars_inner">Style</div>
            <div class="suggest-search-post">
              <ais-index index-name="dev_vnfds">
                <ais-stats>
                  <template v-slot="{ nbHits }">
                    <div class="modal_search-ttl">
                      <h4>Bài viết</h4>
                      <div
                        v-if="keyword.length"
                        class="modal_search-ttl-chil"
                        @click="viewResult('vnfd')"
                      >
                        <p>Xem thêm {{ showLimitStat(nbHits) }} kết quả</p>
                        <i class="dsi dsi-act-arrow-forward"></i>
                      </div>
                    </div>
                  </template>
                </ais-stats>

                <ais-hits>
                  <template v-slot="{ items }">
                    <div class="post-group" v-for="(post, index) in items" :key="index">
                      <div class="post-group-info">
                        <div class="header-post unset_wrap">
                          <div class="avatar">
                            <a
                              v-if="post.brand_id && post.brand_id != '0'"
                              :href="
                                '/?dispatch=companies.view&company_id=' +
                                post.brand_id +
                                '&tab=style'
                              "
                            >
                              <img
                                width="36"
                                height="36"
                                v-lazy="_companyAvatar(post.company_logo.theme)"
                              />
                            </a>
                            <router-link
                              v-else
                              :to="{
                                name: 'profile',
                                query: { profile_id: post.user_id },
                              }"
                            >
                              <img
                                width="40"
                                height="40"
                                v-lazy="_userAvatar(post.avatar)"
                              />
                            </router-link>
                            <div class="detail-avatar">
                              <a
                                class="name"
                                v-if="post.brand_id && post.brand_id != '0'"
                                :href="
                                  '/?dispatch=companies.view&company_id=' +
                                  post.brand_id +
                                  '&tab=style'
                                "
                              >
                                {{ post.company_name }}
                              </a>
                              <router-link
                                class="name"
                                v-else
                                :to="{
                                  name: 'profile',
                                  query: { profile_id: post.user_id },
                                }"
                              >
                                {{ post.user_name }}
                              </router-link>

                              <div class="time-day d-flex align-items-center">
                                <span>{{ _momentFromNow(post.timestamp) }}</span>
                              </div>
                            </div>
                          </div>
                          <a
                            v-if="post.brand_id && post.brand_id != '0'"
                            class="primary-secondary"
                            :href="
                              '/?dispatch=companies.view&company_id=' +
                              post.brand_id +
                              '&tab=style'
                            "
                            :title="post.company_name"
                          >
                            <span class="primary-secondary_text">Ghé cửa hàng</span>
                            <i class="dsi dsi-manage"></i>
                          </a>
                          <router-link
                            v-else
                            class="primary-secondary"
                            :to="{ name: 'profile', query: { profile_id: post.user_id } }"
                            :title="post.user_name"
                          >
                            <span class="primary-secondary_text">Trang cá nhân</span>
                            <i class="dsi dsi-chevron-right"></i>
                          </router-link>
                        </div>
                        <div
                          class="post-group-info_wrapper d-flex justify-content-between"
                        >
                          <div class="post-group-info_left">
                            <div class="tag-link">
                              <router-link
                                v-for="(tag, key) in post.tags"
                                :to="{ name: 'tags', query: { tag: tag.tag } }"
                                v-bind:key="key"
                                >#{{ $filters.trim(tag.tag) }}&nbsp;
                              </router-link>
                            </div>
                            <div class="desc" v-text="post.short_description"></div>
                            <router-link :to="_vnfdUrl(post.seo_name)" class="see-more">
                              Xem thêm
                            </router-link>
                          </div>
                          <div class="post-group-info_right">
                            <router-link
                              :to="_vnfdUrl(post.seo_name)"
                              class="img-post-wrapper dosiin_post-detail"
                            >
                              <img
                                v-lazy="_postImage(post.main_pair)"
                                width="204"
                                height="204"
                              />
                            </router-link>
                            <div
                              class="dosiin-tag-feed d-flex align-items-center justify-content-between"
                              v-if="post.categories_brands_with_name"
                            >
                              <a
                                class="img-post-wrapper dosiin_post-detail"
                                href="/"
                                title="name post"
                              >
                              </a>
                              <a
                                class="tag-feed-btn dosiin_bs-modal-tag-feed d-flex align-items-center justify-content-between dosiin_mr-2"
                                href=""
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M9.18832 2.78866C9.47476 2.49609 9.86421 2.33203 10.2692 2.33203H13.4159C14.1014 2.33203 14.6662 2.90816 14.6666 3.60728V6.81755C14.6666 7.23076 14.5054 7.6274 14.219 7.91956L9.5866 12.6447C8.99336 13.2499 8.01858 13.2499 7.42533 12.6447L7.3944 12.6132L6.80837 13.2115C6.21512 13.8166 5.24035 13.8166 4.6471 13.2115L1.77825 10.2846C1.185 9.67951 1.185 8.68575 1.77825 8.08062L6.41008 3.35544C6.69653 3.06287 7.08598 2.89881 7.49099 2.89881H9.08034L9.18832 2.78866ZM8.24687 3.74898H7.49099C7.30646 3.74898 7.13021 3.82346 6.99992 3.95654L2.36754 8.68172C2.09286 8.9619 2.09286 9.40337 2.36754 9.68354L5.23693 12.6099C5.51161 12.89 5.94441 12.89 6.21908 12.6099L6.80511 12.0121L4.55648 9.71786C3.96323 9.11273 3.96323 8.11898 4.55648 7.51385L8.24687 3.74898ZM12.1657 4.03237C11.9446 4.03237 11.7326 4.12194 11.5763 4.28138C11.42 4.44081 11.3322 4.65706 11.3322 4.88253C11.3322 5.10801 11.42 5.32426 11.5763 5.48369C11.7326 5.64313 11.9446 5.7327 12.1657 5.7327C12.3867 5.7327 12.5987 5.64313 12.755 5.48369C12.9113 5.32426 12.9992 5.10801 12.9992 4.88253C12.9992 4.65706 12.9113 4.44081 12.755 4.28138C12.5987 4.12194 12.3867 4.03237 12.1657 4.03237Z"
                                    fill="#E6E6E6"
                                  ></path>
                                </svg>
                                <span class="dosiin_ml-1">{{
                                  Object.keys(post.categories_brands_with_name).length
                                }}</span>
                              </a>
                              <a
                                class="tag-feed-btn dosiin_bs-modal-tag-feed d-flex align-items-center justify-content-between"
                                href="/"
                                title="Hastag"
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M7.43718 1.96278H10.6486C11.0313 1.96278 11.3987 2.11362 11.67 2.38281C11.9414 2.65207 12.0942 3.01773 12.0942 3.39949V5.90496H12.7343V3.39949C12.7343 2.85188 12.5151 2.32625 12.1242 1.93835C11.7332 1.55038 11.2024 1.33203 10.6486 1.33203H3.58574C3.03186 1.33203 2.5011 1.55038 2.11011 1.93835C1.7192 2.32625 1.5 2.85188 1.5 3.39949V10.4078C1.5 10.9554 1.71921 11.4811 2.11011 11.8689C2.5011 12.2569 3.03186 12.4753 3.58574 12.4753H5.00934V11.8445H3.58574C3.20304 11.8445 2.83557 11.6937 2.56428 11.4245C2.29292 11.1552 2.14007 10.7895 2.14007 10.4078V3.39949C2.14007 3.01773 2.29292 2.65207 2.56428 2.38281C2.83556 2.11362 3.20302 1.96278 3.58574 1.96278H6.79711V4.37189H7.43718V1.96278ZM13.2971 7.90233H8.44143C8.1217 7.90233 7.81549 8.0284 7.59007 8.25208C7.36473 8.47568 7.23854 8.77847 7.23854 9.09375V13.4739C7.23854 13.7892 7.36473 14.092 7.59007 14.3156C7.81549 14.5393 8.1217 14.6654 8.44143 14.6654H13.2971C13.6168 14.6654 13.923 14.5393 14.1485 14.3156C14.3738 14.092 14.5 13.7892 14.5 13.4739V9.09375C14.5 8.77847 14.3738 8.47568 14.1485 8.25208C13.923 8.02839 13.6168 7.90233 13.2971 7.90233ZM11.1401 10.5042V8.53308H10.5V10.5042H11.1401Z"
                                    fill="white"
                                  ></path>
                                </svg>
                                <span class="dosiin_ml-1">{{
                                  Object.keys(post.products).length
                                }}</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </ais-hits>
              </ais-index>
            </div>
          </div>
        </section>
        <SearchBrand v-if="query.tab == 'brand'" />
        <SearchKeyWord v-if="query.tab == 'keyword'" />
        <SearchProduct v-if="query.tab == 'product'" />
        <SearchStyle v-if="query.tab == 'vnfd'" />
      </div>
      <div class="search_page-right" :class="[$style.search_ticky_sidebar]">
        <HashTag />
        <Policy />
      </div>
    </div>
  </ais-instant-search>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import SearchStyle from "@/algolia/search/desktop/SearchStyle";
import SearchKeyWord from "@/algolia/search/desktop/SearchKeyWord";
import SearchBrand from "@/algolia/search/desktop/SearchBrand";
import SearchProduct from "@/algolia/search/desktop/SearchProduct";
import HashTag from "@/components/hashtag/HashTag";
import Policy from "@/components/common/Policy";

import { _getCookie } from "@/utlis/cookie";
import { inject, ref, watch } from "vue";
import { createInsightsMiddleware } from "instantsearch.js/es/middlewares";
import aa from "search-insights";

export default {
  components: {
    SearchStyle,
    SearchKeyWord,
    SearchBrand,
    SearchProduct,
    HashTag,
    Policy,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const currentUser = inject("currentUser");

    const keyword = ref(route.query.keyword ? route.query.keyword : "");
    const query = ref({
      keyword: "",
      tab: route.query.tab ? route.query.tab : "all",
    });
    const insightsMiddleware = createInsightsMiddleware({
      insightsClient: aa,
    });

    aa("setUserToken", currentUser.value ? currentUser.value.user_id : _getCookie("dsi"));

    watch(
      () => route.query,
      (newQuery) => {
        keyword.value = newQuery.keyword;
        query.value = newQuery;
      }
    );

    const viewResult = (tab) => {
      router.replace({
        name: "search",
        query: {
          keyword: keyword.value,
          tab: tab,
        },
      });
    };

    return {
      query,
      keyword,
      // methods
      viewResult,
      middlewares: [insightsMiddleware],
    };
  },
};
</script>

<style module>
.search_ticky_sidebar {
  position: sticky;
  top: 103px;
  height: 100%;
}
</style>
