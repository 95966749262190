<template>
  <div class="search-module" v-if="mode == 'search'">
    <div class="search_main" id="dosiin_search-main">
      <ais-instant-search :search-client="searchClientKeys" index-name="dev_products">
        <HeaderAlgolia @update:keyword-value="keyword = $event" :keyword="keyword" />

        <ais-configure :hitsPerPage="8" :enable-personalization.camel="true" />

        <SearchKeyWord :readMore="readMore" @change-mode="changeMode" />

        <div class="shopping-title">
          <p class="center-line">Shopping</p>
        </div>

        <SearchBrand :readMore="readMore" @change-mode="changeMode" />
        <SearchProduct :readMore="readMore" @change-mode="changeMode" />

        <div class="style-title">
          <p class="center-line">Style</p>
        </div>

        <SearchStyle :readMore="readMore" @change-mode="changeMode" />
      </ais-instant-search>
    </div>
  </div>

  <ResultPage v-else></ResultPage>
</template>

<script>
import HeaderAlgolia from "@/algolia/search/Header.vue";
import SearchStyle from "@/algolia/search/mb/SearchStyle";
import SearchKeyWord from "@/algolia/search/mb/SearchKeyWord";
import SearchBrand from "@/algolia/search/mb/SearchBrand";
import SearchProduct from "@/algolia/search/mb/SearchProduct";
import ResultPage from "@/pages/search/Result";
import { watch, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "search_page",
  components: {
    HeaderAlgolia,
    SearchStyle,
    SearchKeyWord,
    SearchBrand,
    SearchProduct,
    ResultPage,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const mode = ref(route.query.keyword ? "result" : "search");
    const keyword = ref(route.query && route.query.keyword ? route.query.keyword : "");
    const readMore = ref(false);

    watch(
      () => keyword.value,
      (newKeyword) => {
        if (newKeyword.length > 0) {
          readMore.value = true;
        } else {
          readMore.value = false;
        }
      }
    );

    watch(
      () => route.query,
      (newQuery) => {
        if (newQuery.keyword) {
          keyword.value = newQuery.keyword;
        } else {
          mode.value = "search";
        }
      }
    );

    const changeMode = (tab) => {
      mode.value = "result";

      router.replace({
        name: "search",
        query: {
          keyword: keyword.value,
          tab: tab,
        },
      });
    };

    return {
      mode,
      keyword,
      readMore,
      // methods
      changeMode,
    };
  },
};
</script>

<style>
.primary-link_text {
  background: linear-gradient(135deg, #ed55c7 0, #6756ca 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
}
</style>
