<template>
  <section class="search-page-section">
    <ais-index index-name="dev_vnfds">
      <div class="header-section">
        <h3 class="header-section-left-side section-title">Bài viết</h3>
        <div class="header-section-right-side">
          <a
            v-if="readMore"
            class="primary-button"
            title="Xem thêm"
            @click="$emit('changeMode', 'search_style')"
          >
            <ais-stats>
              <template v-slot="{ nbHits }">
                <span class="text-pink-gradient">
                  <span class="primary-link_text"
                    >Xem thêm {{ showLimitStat(nbHits) }} kết quả</span
                  >
                </span>
              </template>
            </ais-stats>
            <i class="dsi dsi-act-arrow-forward"></i>
          </a>
        </div>
      </div>

      <ais-hits>
        <template v-slot="{ items }">
          <div class="suggest-search-post" v-for="(post, index) in items" :key="index">
            <div class="post-group post-brand">
              <div class="post-group-info">
                <div class="header-post">
                  <div class="avatar">
                    <a
                      v-if="post.brand_id && post.brand_id != '0'"
                      :href="
                        '/?dispatch=companies.view&company_id=' +
                        post.brand_id +
                        '&tab=style'
                      "
                    >
                      <img
                        width="40"
                        height="40"
                        v-lazy="_companyAvatar(post.company_logo.theme)"
                      />
                    </a>
                    <router-link
                      v-else
                      :to="{ name: 'profile', query: { profile_id: post.user_id } }"
                    >
                      <img width="40" height="40" v-lazy="_userAvatar(post.avatar)" />
                    </router-link>

                    <div class="detail-avatar">
                      <a
                        class="name"
                        v-if="post.brand_id && post.brand_id != '0'"
                        :href="
                          '/?dispatch=companies.view&company_id=' +
                          post.brand_id +
                          '&tab=style'
                        "
                      >
                        {{ post.company_name }}
                      </a>
                      <router-link
                        class="name"
                        v-else
                        :to="{ name: 'profile', query: { profile_id: post.user_id } }"
                      >
                        {{ post.user_name }}
                      </router-link>
                      <div class="time">
                        <span>{{ _momentFromNow(post.timestamp) }}</span>
                      </div>
                    </div>
                  </div>
                  <a
                    v-if="post.brand_id && post.brand_id != '0'"
                    class="brand_follow-btn primary-button button follow-with-text-btn"
                    :href="
                      '/?dispatch=companies.view&company_id=' +
                      post.brand_id +
                      '&tab=style'
                    "
                    :title="post.company_name"
                  >
                    <i class="dsi dsi-act-store"></i>
                    <span class="follow-btn-text">Ghé cửa hàng</span>
                  </a>
                  <router-link
                    v-else
                    class="brand_follow-btn primary-button button follow-with-text-btn"
                    :to="{ name: 'profile', query: { profile_id: post.user_id } }"
                    :title="post.user_name"
                  >
                    <span class="follow-btn-text">Ghé thăm</span>
                  </router-link>
                </div>
                <div class="post-group-info_wrapper d-flex justify-content-between">
                  <div class="post-group-info_left">
                    <div class="tag-link" v-if="post.tags">
                      <router-link
                        v-for="(tag, key) in post.tags"
                        :to="{ name: 'tags', query: { tag: tag.tag } }"
                        v-bind:key="key"
                        >#{{ $filters.trim(tag.tag) }}&nbsp;
                      </router-link>
                    </div>
                    <div class="desc" v-text="post.short_description"></div>
                    <router-link class="see-more" :to="_vnfdUrl(post.seo_name)">
                      Xem thêm
                    </router-link>
                  </div>
                  <div class="post-group-info_right">
                    <router-link
                      :to="_vnfdUrl(post.seo_name)"
                      class="img-post-wrapper dosiin_post-detail"
                    >
                      <img
                        v-lazy="_postImage(post.main_pair)"
                        width="153"
                        height="153"
                        class="load_scroll cm-image pict"
                      />
                      <div
                        class="dosiin-tag-feed d-flex align-items-center justify-content-between"
                        v-if="post.categories_brands_with_name"
                      >
                        <a
                          class="tag-feed-btn dosiin_bs-modal-tag-feed d-flex align-items-center justify-content-between dosiin_mr-2"
                          title="Hashtag"
                          @click="tagPost(post)"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M9.18832 2.78866C9.47476 2.49609 9.86421 2.33203 10.2692 2.33203H13.4159C14.1014 2.33203 14.6662 2.90816 14.6666 3.60728V6.81755C14.6666 7.23076 14.5054 7.6274 14.219 7.91956L9.5866 12.6447C8.99336 13.2499 8.01858 13.2499 7.42533 12.6447L7.3944 12.6132L6.80837 13.2115C6.21512 13.8166 5.24035 13.8166 4.6471 13.2115L1.77825 10.2846C1.185 9.67951 1.185 8.68575 1.77825 8.08062L6.41008 3.35544C6.69653 3.06287 7.08598 2.89881 7.49099 2.89881H9.08034L9.18832 2.78866ZM8.24687 3.74898H7.49099C7.30646 3.74898 7.13021 3.82346 6.99992 3.95654L2.36754 8.68172C2.09286 8.9619 2.09286 9.40337 2.36754 9.68354L5.23693 12.6099C5.51161 12.89 5.94441 12.89 6.21908 12.6099L6.80511 12.0121L4.55648 9.71786C3.96323 9.11273 3.96323 8.11898 4.55648 7.51385L8.24687 3.74898ZM12.1657 4.03237C11.9446 4.03237 11.7326 4.12194 11.5763 4.28138C11.42 4.44081 11.3322 4.65706 11.3322 4.88253C11.3322 5.10801 11.42 5.32426 11.5763 5.48369C11.7326 5.64313 11.9446 5.7327 12.1657 5.7327C12.3867 5.7327 12.5987 5.64313 12.755 5.48369C12.9113 5.32426 12.9992 5.10801 12.9992 4.88253C12.9992 4.65706 12.9113 4.44081 12.755 4.28138C12.5987 4.12194 12.3867 4.03237 12.1657 4.03237Z"
                              fill="#E6E6E6"
                            ></path>
                          </svg>
                          <span
                            class="dosiin_ml-1"
                            v-text="Object.keys(post.categories_brands_with_name).length"
                          ></span>
                        </a>
                        <a
                          class="tag-feed-btn dosiin_bs-modal-tag-feed d-flex align-items-center justify-content-between"
                          v-if="post.products"
                          :title="`${Object.keys(post.products).length} sản phẩm`"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.43718 1.96278H10.6486C11.0313 1.96278 11.3987 2.11362 11.67 2.38281C11.9414 2.65207 12.0942 3.01773 12.0942 3.39949V5.90496H12.7343V3.39949C12.7343 2.85188 12.5151 2.32625 12.1242 1.93835C11.7332 1.55038 11.2024 1.33203 10.6486 1.33203H3.58574C3.03186 1.33203 2.5011 1.55038 2.11011 1.93835C1.7192 2.32625 1.5 2.85188 1.5 3.39949V10.4078C1.5 10.9554 1.71921 11.4811 2.11011 11.8689C2.5011 12.2569 3.03186 12.4753 3.58574 12.4753H5.00934V11.8445H3.58574C3.20304 11.8445 2.83557 11.6937 2.56428 11.4245C2.29292 11.1552 2.14007 10.7895 2.14007 10.4078V3.39949C2.14007 3.01773 2.29292 2.65207 2.56428 2.38281C2.83556 2.11362 3.20302 1.96278 3.58574 1.96278H6.79711V4.37189H7.43718V1.96278ZM13.2971 7.90233H8.44143C8.1217 7.90233 7.81549 8.0284 7.59007 8.25208C7.36473 8.47568 7.23854 8.77847 7.23854 9.09375V13.4739C7.23854 13.7892 7.36473 14.092 7.59007 14.3156C7.81549 14.5393 8.1217 14.6654 8.44143 14.6654H13.2971C13.6168 14.6654 13.923 14.5393 14.1485 14.3156C14.3738 14.092 14.5 13.7892 14.5 13.4739V9.09375C14.5 8.77847 14.3738 8.47568 14.1485 8.25208C13.923 8.02839 13.6168 7.90233 13.2971 7.90233ZM11.1401 10.5042V8.53308H10.5V10.5042H11.1401Z"
                              fill="#E6E6E6"
                            ></path>
                          </svg>
                          <span class="dosiin_ml-1">{{
                            Object.keys(post.products).length
                          }}</span>
                        </a>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </ais-hits>
    </ais-index>

    <!-- <div v-if="readMore" class="seemore-btn" style="padding: 0px 18px;" @click="$emit('changeMode','search_keyword')">
            <a class="primary-button button" title="Xem thêm kết quả">
                <span class="primary-link_text">Xem thêm kết quả</span>
                <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="see-more-right-arrow-custom" fill-rule="evenodd" clip-rule="evenodd" d="M17.3333 6.39359L12.082 0.853147C11.9693 0.731823 11.819 0.666668 11.6577 0.666668C11.5052 0.666668 11.3615 0.725083 11.2532 0.832926C11.1383 0.943016 11.0742 1.09355 11.072 1.25531C11.0698 1.41483 11.1272 1.56536 11.2355 1.67994L12.5616 3.07741L14.5971 5.22529L15.1386 5.79596H14.3585H12.3229H1.26117C1.04457 5.79596 0.85008 5.90829 0.748413 6.09028C0.640116 6.27676 0.640116 6.50817 0.748413 6.69465C0.85229 6.87888 1.04678 6.99122 1.25454 6.99122H12.3229H14.3585H15.1386L14.5971 7.56189L12.5616 9.70976L11.2377 11.1072C11.0676 11.2847 11.0189 11.5409 11.114 11.7655C11.209 11.9835 11.4212 12.125 11.6532 12.125H11.6555H11.6643C11.8212 12.1205 11.9737 12.0509 12.0798 11.9363L17.3333 6.39359Z" fill="url(#see-more-right-arrow)"></path>
                    <defs>
                        <linearGradient id="see-more-right-arrow" x1="0.667191" y1="0.666668" x2="11.3658" y2="16.2278" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#ED55C7"></stop>
                            <stop offset="1" stop-color="#6756CA"></stop>
                        </linearGradient>
                    </defs>
                </svg>
            </a>
        </div> -->
  </section>
</template>

<script>
export default {
  props: {
    readMore: Boolean,
  },
};
</script>
