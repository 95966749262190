<template>
    <section class="search-page-section">
        <div class="header-section">
            <h3 class="header-section-left-side section-title">Thương hiệu</h3>
            <div class="header-section-right-side">                    
                <a v-if="readMore" class="primary-button" title="Xem thêm" @click="$emit('changeMode','search_brand')">                        
                    <ais-index index-name="dev_companies">
                        <ais-stats>
                            <template v-slot="{ nbHits }">
                                <span class="text-pink-gradient">
                                    <span class="primary-link_text">Xem thêm {{ showLimitStat(nbHits) }} kết quả</span>
                                </span>
                            </template>
                        </ais-stats>
                    </ais-index>                      
                    <i class="dsi dsi-act-arrow-forward"></i>                    
                </a>                
            </div>
        </div>
        
        <div class="brand-list">
            <ais-index index-name="dev_companies">
                <ais-hits>
                    <template v-slot="{ items }">
                        <ul class="brand-list-wrapper">
                            <li class="brand-modal_item" v-for="item in items" :key="item.objectID">
                                <a :href="`/${item.seo_name}`" :title="item.page_title">
                                    <div class="brand_logo">
                                        <img 
                                            v-lazy="_companyAvatar(item.logos.theme)"
                                            :alt="item.company" 
                                            class="brand-logo_img"
                                            width="62"
                                            height="62"
                                            style="width: 62px;height: 62px"
                                        />

                                        <h5 class="brand-name truncate">
                                            <ais-highlight 
                                                :class-names="{'ais-Highlight-highlighted': 'primary-link_text'}"
                                                :hit="item"
                                                attribute="company"
                                            />
                                        </h5>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </template>
                </ais-hits>
            </ais-index>
        </div>
    </section>
</template>

<script>
    export default {
        props:{
            readMore : Boolean
        },
    }
</script>