<template>
  <section class="search-page-section">
    <div class="header-section">
      <h3 class="header-section-left-side section-title">Sản phẩm</h3>
      <div class="header-section-right-side">
        <a
          v-if="readMore"
          class="primary-button"
          title="Xem thêm"
          @click="$emit('changeMode', 'search_product')"
        >
          <ais-stats>
            <template v-slot="{ nbHits }">
              <span class="text-pink-gradient">
                <span class="primary-link_text"
                  >Xem thêm {{ showLimitStat(nbHits) }} kết quả</span
                >
              </span>
            </template>
          </ais-stats>
          <i class="dsi dsi-act-arrow-forward"></i>
        </a>
      </div>
    </div>

    <div class="product-list">
      <ais-hits
        :class-names="{
          'ais-Hits': 'dosiin_modal-products-swiper',
        }"
      >
        <template v-slot="{ items, sendEvent }">
          <ul class="product-list-wrapper">
            <li
              class="search-product-item"
              v-for="product in items"
              :key="product.objectID"
            >
              <span
                v-if="product.product < 0"
                style="
                  position: absolute;
                  z-index: 1;
                  top: 0;
                  left: 0;
                  background: rgb(0, 0, 0, 0.4);
                  color: #fff;
                  font-size: 15px;
                  font-weight: normal;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 0 0 5px 5px;
                "
                >HẾT SẢN PHẨM</span
              >
              <div class="product-item">
                <router-link
                  class="product-item_img"
                  :to="_productUrl(product)"
                  :title="product.product"
                  @click.native="
                    sendEvent('click', product, 'product - search - clicked')
                  "
                >
                  <img
                    v-lazy="
                      _productThumbnailSticker(
                        product.main_pair,
                        product.sticker.main_pair
                      )
                    "
                    :title="product.product"
                  />
                </router-link>

                <dl class="product-item_summary">
                  <dt class="product-item_brand truncate">
                    <router-link
                      :to="_brandUrl(product.seo_name_company)"
                      :title="product.company_name"
                      v-text="product.company_name"
                    >
                    </router-link>
                  </dt>

                  <dd class="product-item_name truncate">
                    <router-link :to="_productUrl(product)" :title="product.product">
                      <ais-highlight
                        :class-names="{
                          'ais-Highlight-highlighted': 'primary-link_text',
                        }"
                        attribute="product"
                        :hit="product"
                      />
                    </router-link>
                  </dd>

                  <div
                    class="product-item_sale"
                    v-if="product.list_price > product.price"
                  >
                    <dd
                      class="inline-bl _sale_origin product-item_sale_origin dosiin_mr-2"
                    >
                      <span>{{ currencyFormat(product.list_price) }}</span>
                    </dd>
                    <dd
                      v-if="discountPrc(product) && discountPrc(product) > 0"
                      class="inline-bl _sale_origin product-item_sale_discount"
                    >
                      -{{ Math.floor(discountPrc(product)) }}%
                    </dd>
                  </div>
                  <div class="product-item_sale" v-else></div>

                  <dd class="product-item_price">
                    <span>{{ currencyFormat(product.price) }}</span>
                  </dd>
                </dl>
              </div>
            </li>
          </ul>
        </template>
      </ais-hits>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    readMore: Boolean,
  },
};
</script>
