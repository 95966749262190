<template>
    <header>
        <ais-search-box
            placeholder="Nhập từ khoá tìm kiếm"
            submit-title="Tìm kiếm"
            reset-title="Xóa"
            autofocus
        >
            <template v-slot="{  refine }">
                <div class="search-header" id="dosiin_search-header">
                    <div class="search-form">
                        <button class="btn-cancel dosiin_cancel-btn" type="button" @click="goBackSearch">
                            <i class="dsi dsi-s24 dsi-chevron-left"></i>
                        </button>
                        <form class="ais-SearchBox-form dosiin_w-100" novalidate>
                            <div class="input-group" id="dosiin_search-input-group">
                                <div class="search-icon">
                                    <i class="dsi dsi-search"></i>
                                </div>
                                <input
                                    class="search-input"
                                    type="text"
                                    name="keyword"
                                    autocomplete="off" 
                                    autocorrect="off" 
                                    autocapitalize="off"
                                    v-model="keyword"
                                    ref="searchInput"
                                    placeholder="Nhập từ khoá tìm kiếm"
                                    @input="refine($event.currentTarget.value) , $emit('update:keywordValue', $event.currentTarget.value)"
                                />

                                <ais-configure
                                    :hitsPerPage="10"
                                    :query="keyword"
                                />

                                <button v-if="keyword && keyword.length > 0"  @click="clearKeyword" type="button" class="ais-SearchBox-reset btn-clear" title="Xóa từ khóa">
                                    <i class="ais-SearchBox-resetIcon dsi dsi-s24 dsi-close-circle"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </template>
        </ais-search-box>
    </header>
</template>

<script>
    import { watch ,ref } from 'vue';
    import { useRoute , useRouter } from 'vue-router';

    export default {
        emits: ['update:keywordValue'],
        setup(){
            const route  = useRoute();
            const router = useRouter()
            const keyword = ref(route.query.keyword ? route.query.keyword : '');

            watch(() => route.query , (newQuery) => {
                keyword.value = newQuery.keyword;
            });

            const goBackSearch = () => {
                if(keyword.value && keyword.value.length > 0){
                    router.replace({name : 'search'});
                }else{
                    router.back();
                }
            }

            const clearKeyword = () => {
                keyword.value = '';
                router.replace({ name: 'search' });
            }

            return {
                keyword,
                goBackSearch,
                clearKeyword
            }
        },
    }
</script>