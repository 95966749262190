<template>
  <ais-index index-name="dev_products_query_suggestions">
    <ais-hits ref="searchHits">
      <template v-slot="{ items }">
        <div class="search_key">
          <div class="modal_search-hastag">
            <ul>
              <li v-for="(item, index) in items" :key="index">
                <i class="dsi dsi-s16 dsi-search"></i>
                <router-link
                  :to="{ name: 'search', query: { keyword: item.query } }"
                  :title="item.query"
                  replace
                >
                  <span>#{{ item.query }}</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </ais-hits>
    <AlgoligaPagination />
  </ais-index>
</template>

<script>
import AlgoligaPagination from "@/algolia/search/desktop/Pagination";
export default {
  components: {
    AlgoligaPagination,
  },
};
</script>
